import { useReducer } from 'react';
import { instance } from '../api/ApiInstance';
import { TOKEN, get } from '../storage/LocalStorageManager';

const initialState = {
	isOpenSnack: false,
	getRecallsProgress: false,
	getRecallsError: false,
	editRecallSuccess: false,
	editRecallError: false,
	initialRoute: 'dashboard',
	recalls: [],
};

const appReducer = (state, action) => {
	switch (action.type) {
		case 'OPEN_SNACK': {
			return {
				...state,
				isOpenSnack: true,
			};
		}
		case 'CLOSE_SNACK': {
			return {
				...state,
				isOpenSnack: false,
			};
		}
		case 'RECALL_PROGRESS': {
			return {
				...state,
				getRecallsProgress: true,
				getRecallsError: false,
			};
		}
		case 'RECALL_ERROR': {
			return {
				...state,
				getRecallsProgress: false,
				getRecallsError: true,
			};
		}
		case 'RECALL_SUCCESS': {
			return {
				...state,
				getRecallsProgress: false,
				getRecallsError: false,
				recalls: action.payload,
			};
		}
		case 'EDIT_RECALL_ERROR': {
			return {
				...state,
				editRecallError: true,
				editRecallSuccess: false,
			};
		}
		case 'EDIT_RECALL_SUCCESS': {
			return {
				...state,
				editRecallError: false,
				editRecallSuccess: true,
			};
		}
		case 'INITIAL_ROUTE': {
			return {
				...state,
				initialRoute: action.payload,
			};
		}
		case 'RECALL_SMS_SUCCESS': {
			return {
				...state,
				recallSmsError: false,
				recallSmsSuccess: true,
			};
		}
		case 'RECALL_SMS_ERROR': {
			return {
				...state,
				recallSmsError: true,
				recallSmsSuccess: false,
			};
		}
	}
};

const useApp = ({ params, data, callbacks }) => {
	const [state, dispatch] = useReducer(appReducer, {
		...initialState,
	});

	const openSnack = () => {
		dispatch({ type: 'OPEN_SNACK' });
	};

	const closeSnack = () => {
		dispatch({ type: 'CLOSE_SNACK' });
	};
	const getRecalls = async () => {
		dispatch({ type: 'RECALL_PROGRESS' });

		try {
			const response = await instance.get('api/agents/recalls', {
				headers: {
					'X-Auth-Token': get(TOKEN),
				},
			});
			console.log('RECAllS ==> ', response.data);
			dispatch({ type: 'RECALL_SUCCESS', payload: response.data });
		} catch (e) {
			console.log('RECAllS ERROR ==> ', e);
			dispatch({ type: 'RECALL_ERROR', payload: e || 'Something went wrong' });
		}
	};

	const editRecall = async (request) => {
		try {
			const response = await instance.put('api/agents/recalls/update', request, {
				headers: {
					'X-Auth-Token': get(TOKEN),
				},
			});
			console.log('EDIT RECAll ==> ', response.data);
			dispatch({ type: 'EDIT_RECALL_SUCCESS', payload: true });
		} catch (e) {
			console.log('EDIT RECALL ERROR ==> ', e);
			dispatch({ type: 'EDIT_RECALL_ERROR', payload: true });
		}
	};

	const recallSms = async (request) => {
		try {
			const response = await instance.post('api/agents/recalls/sms/send', request, {
				headers: {
					'X-Auth-Token': get(TOKEN),
				},
			});
			console.log('RECAll SMS SUCCESS ==> ', response.data);
			dispatch({ type: 'RECALL_SMS_SUCCESS', payload: true });
		} catch (e) {
			dispatch({ type: 'RECALL_SMS_ERROR', payload: true });
		}
	};

	const setInitialTabRoute = (route) => {
		dispatch({ type: 'INITIAL_ROUTE', payload: route });
	};
	return {
		...state,
		openSnack,
		closeSnack,
		getRecalls,
		setInitialTabRoute,
		editRecall,
		recallSms,
	};
};

export default useApp;
