const get = (key) => {
	const value = localStorage.getItem(key);
	return value ? JSON.parse(value) : null;
};

const set = (key, value) => {
	localStorage.setItem(key, JSON.stringify(value));
};

const remove = (key) => {
	localStorage.removeItem(key);
};

const USER = 'USER';
const EMAIL = 'EMAIL';
const TOKEN = 'TOKEN';
const STORE_SLUG = 'STORE_SLUG';

export { USER, STORE_SLUG, EMAIL, TOKEN, get, set, remove };
