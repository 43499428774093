import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SendBirdProvider, ChannelList, ChannelSettings, Channel } from 'sendbird-uikit';
import 'sendbird-uikit/dist/index.css';
import { Box, Typography } from '@material-ui/core';
import CustomizedMessageItem from '../components/CustomizedMessageItems';
import CustomizedChannelPreviewItem from '../components/CustomizedMessageItems/CustomChannelPreview';
import { makeStyles } from '@material-ui/core/styles';
import './Dashboard.css';
import AppHeader from '../components/AppHeader';
import moment from 'moment';
import 'moment/locale/it';
import useApp from '../hooks/useApp';
import { ContextFactory, CONTEXTS, useAppContext } from '../hooks/context/ContextFactory';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { get, remove, EMAIL, USER } from '../storage/LocalStorageManager';

moment.locale('it');

const myColorSet = {
	'--sendbird-dark-primary-100': '#e6caa9',
	'--sendbird-dark-primary-200': '#e2c19a',
	'--sendbird-dark-primary-300': '#deb98c',
	'--sendbird-dark-primary-400': '#dab07d',
	'--sendbird-dark-primary-500': '#d6a76f',
	'--sendbird-light-primary-100': '#e6caa9',
	'--sendbird-light-primary-200': '#e2c19a',
	'--sendbird-light-primary-300': '#deb98c',
	'--sendbird-light-primary-400': '#dab07d',
	'--sendbird-light-primary-500': '#d6a76f',
	'--sendbird-add-reaction-button-border-hover': '#d6a76f',
	'--sendbird-selected-reaction-button-border-hover': '#d6a76f',
	'--sendbird-message-input-border-active': '#d6a76f',
};

const myStringSet = {
	CHANNEL__MESSAGE_INPUT__PLACE_HOLDER: 'Inserisci il messaggio',
	TYPING_INDICATOR__IS_TYPING: 'sta scrivendo...',
	MESSAGE_STATUS__SENDING_FAILED: `Impossibile inviare il messaggio`,
	MESSAGE_EDITED: '(modificato)',
	TRYING_TO_CONNECT: 'Tentativo di connessione...',
	MESSAGE_STATUS__TRY_AGAIN: 'Riprova',
	MODAL__DELETE_MESSAGE__TITLE: 'Vuoi eliminare questo messaggio?',
	BUTTON__CANCEL: 'Annulla',
	BUTTON__DELETE: 'Elimina',
	BUTTON__SAVE: 'Salva',
	BUTTON__CREATE: 'Crea',
	BUTTON__CLOSE: 'Chiudi',
	PLACE_HOLDER__WRONG: 'Qualcosa è andato storto',
};

const Dashboard = () => {
	const { ContextProvider } = ContextFactory(CONTEXTS.APP);
	const classes = useStyles();
	const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
	const history = useHistory();
	const [channelUrl, setCurrentChannelUrl] = useState('nesto');
	const [currentChannel, setCurrentChannel] = useState(null);
	const matches = useMediaQuery('(min-width:600px)');

	const userId = get(USER);

	return (
		<ContextProvider>
			{matches ? (
				<SendBirdProvider
					colorSet={myColorSet}
					stringSet={myStringSet}
					userId={userId}
					appId={'1A2F3370-1790-4A3D-A152-6ED0E6CA528C'}
				>
					<Box display="flex" className={classes.wrapper}>
						<Box className={classes.channelList}>
							<ChannelList
								renderChannelPreview={({ channel, onLeaveChannel }) => (
									<CustomizedChannelPreviewItem
										userId={userId}
										channel={channel}
										onLeaveChannel={onLeaveChannel}
										currentChannelUrl={channelUrl}
										onChannelChange={(channel, ref) => {
											if (channel && channel.url) {
												//console.log('Channel', channel);
												//setCurrentChannelUrl(channel.url);
												//setCurrentChannel(channel);

												//scrollToRef(ref);
												var element = document.getElementById(channel.url);
												element.parentElement.scrollIntoView({
													behavior: 'auto',
													block: 'start',
													inline: 'nearest',
												});
												element.parentElement.click();
											}
										}}
									/>
								)}
								onChannelSelect={(channel) => {
									if (channel && channel.url) {
										setCurrentChannel(channel);
										setCurrentChannelUrl(channel.url);
									}
								}}
								// queries={{
								// 	channelListQuery: {
								// 		includeEmpty: value === 0,
								// 		limit: value === 1 ? 1 : 20,
								// 	},
								// }}
							/>
						</Box>
						<Box className={classes.conversation}>
							<Channel
								channelUrl={channelUrl}
								renderChatItem={({ message, onDeleteMessage, onUpdateMessage }) => (
									<CustomizedMessageItem
										message={message}
										onDeleteMessage={onDeleteMessage}
										onUpdateMessage={onUpdateMessage}
										userId={userId}
										channel={currentChannel}
									/>
								)}
							/>
						</Box>
					</Box>
				</SendBirdProvider>
			) : (
				<div style={{ marginTop: '50%' }}>
					<Typography
						className={classes.placeholder}
					>{`Puoi accedere alla chat solo da desktop o tablet`}</Typography>
				</div>
			)}
		</ContextProvider>
	);
};

const useStyles = makeStyles((theme) => ({
	wrapper: {
		height: '92vh',
	},
	channelList: {},
	conversation: {
		width: 'calc(100% - 320px)',
	},
	channelListHeader: {
		backgroundColor: '#f5f5f5',
		width: '100%',
		height: '100%',
	},
	placeholder: {
		fontSize: 18,
		color: 'black',
		fontWeight: 700,
		textAlign: 'center',
		margin: 10,
	},
}));

export default Dashboard;
