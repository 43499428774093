import { PRODUCTION } from '../api/config';

const pubnubConfig = () => {
	if (PRODUCTION) {
		return {
			publishKey: 'pub-c-3704882a-4cc8-422e-87c9-dac420a0618c',
			subscribeKey: 'sub-c-e3024924-2ceb-11e3-89a9-02ee2ddab7fe',
		};
	}
	return {
		publishKey: 'pub-c-95c31a00-074c-49f1-bbb5-8785aa34e2c7',
		subscribeKey: 'sub-c-0e6b7018-2cec-11e3-89a9-02ee2ddab7fe',
	};
};

export { pubnubConfig };
