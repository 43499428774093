import { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Login from './screens/Login';
import useApp from './hooks/useApp';
import Dashboard from './screens/Dashboard';
import GuardedRoute from './components/GuardedRoute';
import { ContextFactory, CONTEXTS, useAppContext } from './hooks/context/ContextFactory';
import useAuth from './hooks/useAuth';
import Recall from './screens/Recall';
import AppHeader from './components/AppHeader';
import { get, USER } from './storage/LocalStorageManager';
import { PubNubProvider } from 'pubnub-react';
import PubNub from 'pubnub';
import { pubnubConfig } from './utils/helpers';

function useQuery() {
	return new URLSearchParams(window.location.search);
}

const random_string = Math.random().toString(36).slice(-8);
const pubnub = new PubNub({
	publishKey: pubnubConfig().publishKey,
	subscribeKey: pubnubConfig().publishKey,
	uuid: 'iqos_assistant_app' + random_string,
});

const App = () => {
	const { ContextProvider } = ContextFactory(CONTEXTS.APP);
	const { ContextProvider: AuthProvider } = ContextFactory(CONTEXTS.AUTH);
	const query = useQuery();
	const appHookObj = useApp({});

	const contextData = {
		...appHookObj,
	};

	const authHookObj = useAuth({
		params: {},
		data: {},
		callbacks: {},
	});

	const username = query.get('username');
	const password = query.get('password');

	const { login, isLoginRequest } = authHookObj;
	const { initialRoute, setInitialTabRoute } = appHookObj;

	const loggedIn = get(USER) !== null;

	const hasCreds = username && password;

	useEffect(() => {
		if (username && password) {
			login(username, password);
			setInitialTabRoute('recalls');
		} else {
			setInitialTabRoute('dashboard');
		}
	}, []);

	if (username && password && isLoginRequest) {
		return null;
	}

	return (
		<PubNubProvider client={pubnub}>
			<ContextProvider data={contextData}>
				<AuthProvider data={authHookObj}>
					<div className="App">
						<Router>
							{loggedIn && <AppHeader />}
							<Switch>
								<GuardedRoute
									authRoute
									exact
									path="/"
									component={Login}
									redirectTo={window.location.pathname}
								/>
								<GuardedRoute path="/dashboard" exact component={Dashboard} />
								<GuardedRoute path="/recalls" exact component={Recall} />
							</Switch>
						</Router>
					</div>
				</AuthProvider>
			</ContextProvider>
		</PubNubProvider>
	);
};

export default App;
