import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core';
import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import { useAppContext } from '../hooks/context/ContextFactory';

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		'& > * + *': {
			marginTop: 5,
		},
	},
}));

export default function SnackBar(props) {
	const classes = useStyles();

	const {
		closeSnack
	} = useAppContext()

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			closeSnack()
			return;
		}
		closeSnack()
	};

	const renderAlert = (
        snackbarMessage,
        type
    ) => {
		switch (type) {
			case 'error':
				return (
					<Alert onClose={(e) => handleClose(e)} severity="error">
						{snackbarMessage}
					</Alert>
				);
			case 'warning':
				return (
					<Alert onClose={(e) => handleClose(e)} severity="warning">
						{snackbarMessage}
					</Alert>
				);
			case 'success':
				return (
					<Alert onClose={(e) => handleClose(e)} severity="success">
						{snackbarMessage}
					</Alert>
				);
			case 'info':
				return (
					<Alert onClose={(e) => handleClose(e)} severity="info">
						{snackbarMessage}
					</Alert>
				);
		}
	};
	return (
		<div className={classes.root}>
			<Snackbar
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				open={props.isOpen}
				autoHideDuration={2000}
				onClose={(e) => handleClose(e)}
			>
				{renderAlert(props.snackbarMessage, props.type)}
			</Snackbar>
		</div>
	);
}
