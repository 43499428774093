import React, { useMemo, useState, useRef } from 'react';

import {
	Avatar,
	Badge,
	Button,
	ButtonGroup,
	Card,
	CardHeader,
	Fade,
	Paper,
	Popper,
	Typography,
	Box,
	IconButton as MIconButton,
} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { makeStyles, withStyles } from '@material-ui/styles';
import SendBird from 'sendbird';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/it'; // without this line it didn't work
import Moment from 'react-moment';

moment.locale('it');

var sb = new SendBird({ appId: '78E3741A-9841-4897-AD81-6F6E7D12202D' });

export default function CustomizedChannelPreviewItem(props) {
	const classes = useStyles();
	const popperId = 'popper-id';

	// props
	const { userId, channel, onLeaveChannel, currentChannelUrl, sdk, sendUserMessage, onChannelChange } = props;
	const { name } = channel;
	const channelName = name;
	const { lastMessage } = channel;

	//useState
	const [openLeaveChannel, setOpenLeaveChannel] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	const myRef = React.createRef();

	const { unreadMessageCount } = channel;
	const { members } = channel;
	//console.log('Members', members);
	const membersExcludingMe = members.filter(
		(member) => member.userId !== userId && !member.metaData.hasOwnProperty('hidden')
	);
	const [firstMember, secondMember] = membersExcludingMe;

	// useMemo
	const isCurrentChannel = useMemo(() => {
		return channel.url === currentChannelUrl;
	}, [currentChannelUrl, channel.url]);

	const channelAvatar = useMemo(() => {
		if (channel.coverUrl) {
			return <Avatar src={channel.coverUrl} />;
		}
		const { members } = channel;
		const membersExcludingMe = members.filter((member) => member.userId !== userId);
		const [firstMember, secondMember] = membersExcludingMe;
		return (
			<Badge
				overlap="circle"
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				// badgeContent={
				//   membersExcludingMe.length > 1 && (
				//     <SmallAvatar
				//       alt="second member"
				//       src={secondMember && secondMember.profileUrl}
				//     />
				//   )
				// }
			>
				{/* <Avatar src={firstMember && firstMember.profileUrl} /> */}
			</Badge>
		);
	}, [userId, channel]);

	const channelTitle = useMemo(() => {
		if (channelName) {
			return channelName;
		}
		const membersNamesExcludingMe = channel.members
			.filter((member) => member.userId !== userId)
			.map((member) => member.nickname || member.userId)
			.join(', ');
		return membersNamesExcludingMe.length < 30
			? membersNamesExcludingMe.length === 0
				? '(No members)'
				: membersNamesExcludingMe
			: `${membersNamesExcludingMe.slice(0, 30)}...`;
	}, [userId, channel, channelName]);

	// channel type filter
	if (channel.isOpenChannel() || channel.isFrozen || channel.isEphemeral || channel.isSuper || channel.isBroadcast) {
		return null;
	}

	// event handlers
	const handleClickChannelPreviewMenu = (event) => {
		setAnchorEl(event.currentTarget);
		setOpenLeaveChannel(!openLeaveChannel);
		event.stopPropagation();
	};

	const handleBlurChannelPreviewItem = (event) => {
		setOpenLeaveChannel(false);
	};

	const handleClickLeaveChannel = (event) => {
		onLeaveChannel(channel);
	};

	const truncate = function (str) {
		return str && str.length > 40 ? str.substring(0, 37) + '...' : str;
	};

	const doDeleteChannel = (channel) => {
		console.log('DO Deleting channel', channel);
		const response = axios.delete(
			'https://api-78E3741A-9841-4897-AD81-6F6E7D12202D.sendbird.com/v3/group_channels/' + channel.url,
			{
				headers: {
					'Api-Token': '5edef3402937f09790ae67a3e8db23650d561333',
				},
			}
		);
		console.log(response);
	};

	const deleteChannel = (channel, deleteAll) => {
		if (deleteAll) {
			console.log('Searching for channel ' + firstMember.nickname + ' and ' + channel.name);
			doDeleteChannel(channel);

			var channelListQuery = sb.GroupChannel.createMyGroupChannelListQuery();

			channelListQuery.includeEmpty = true;
			channelListQuery.order = 'latest_last_message'; // 'chronological', 'latest_last_message', 'channel_name_alphabetical', and 'metadata_value_alphabetical'
			channelListQuery.limit = 20; // The value of pagination limit could be set up to 100.
			channelListQuery.channelNameContainsFilter = firstMember.nickname;
			channelListQuery.nicknameContainsFilter = channel.name;

			if (channelListQuery.hasNext) {
				channelListQuery.next(function (channelList, error) {
					if (error) {
						return;
					}

					console.log('Channel to delete', channelList);

					if (channelList && channelList.length > 0) {
						for (var i = 0; i < channelList.length; i++) {
							let ch = channelList[i];
							if (ch.name === firstMember.nickname) {
								console.log('Deleting channel', ch.name);

								doDeleteChannel(ch);
							}
						}
					}
				});
			}
		} else {
			doDeleteChannel(channel);
		}
	};

	const changeChannel = () => {
		var channelListQuery = sb.GroupChannel.createMyGroupChannelListQuery();

		channelListQuery.includeEmpty = true;
		channelListQuery.order = 'latest_last_message'; // 'chronological', 'latest_last_message', 'channel_name_alphabetical', and 'metadata_value_alphabetical'
		channelListQuery.limit = 20; // The value of pagination limit could be set up to 100.
		channelListQuery.channelNameContainsFilter = firstMember.nickname;
		channelListQuery.nicknameContainsFilter = channel.name;

		if (channelListQuery.hasNext) {
			channelListQuery.next(function (channelList, error) {
				if (error) {
					return;
				}

				console.log('CH', channelList);

				if (channelList && channelList.length > 0) {
					if (channelList && channelList.length > 0) {
						for (var i = 0; i < channelList.length; i++) {
							let ch = channelList[i];
							if (ch.name === firstMember.nickname) {
								console.log('Changing channel', ch);
								onChannelChange(ch, myRef);
							}
						}
					}
				}
			});
		}
	};

	const getMessage = (message) => {
		if (message === 'direct_call:end') {
			return 'Chiamata terminata';
		}
		if (message === 'direct_call:dial') {
			return 'Chiamata iniziata';
		}
		return message;
	};

	return (
		<div className={classes.root}>
			<Box
				style={{ backgroundColor: isCurrentChannel ? '#e6caa9' : 'white' }}
				display="flex"
				alignItems="flex-start"
				className={classes.content}
			>
				<Typography className={classes.channelNameTitle}>{channel.name}</Typography>
				{channel && channel.lastMessage && (
					<Typography className={classes.message}>{getMessage(channel.lastMessage.message)}</Typography>
				)}

				<Moment className={classes.time} fromNow>
					{channel.createdAt}
				</Moment>
			</Box>
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		height: 100,
		width: '100%',
		border: '1px solid #D3D3D3',
		position: 'relative',
	},
	content: {
		height: '100%',
		width: '100%',
		flexDirection: 'column',
		justifyContent: 'center',
		paddingLeft: 20,
	},
	message: {
		color: 'black',
		fontSize: 12,
		maxWidth: '90%',
		maxHeight: 40,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	time: {
		position: 'absolute',
		bottom: 6,
		right: 16,
		fontSize: 10,
	},
	channelNameTitle: {
		fontWeight: 'bold',
		color: 'black',
		fontSize: 16,
	},
}));
