import { createContext, useContext } from 'react';

export const CONTEXTS = {
	AUTH: 'AUTH',
	APP: 'APP',
};
const contextObject = {};

export const ContextFactory = (contextName) => {
	if (!contextObject[contextName]) {
		const NewContext = createContext();

		const ContextProvider = ({ children, data }) => (
			<NewContext.Provider value={data}>{children}</NewContext.Provider>
		);
		contextObject[contextName] = {
			useContext: () => useContext(NewContext),
			ContextProvider,
		};
	}

	return contextObject[contextName];
};

export const useAuthContext = () => contextObject[CONTEXTS.AUTH].useContext();
export const useAppContext = () => contextObject[CONTEXTS.APP].useContext();
