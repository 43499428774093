import { useReducer } from 'react';
import { instance } from '../api/ApiInstance';
import { USER, EMAIL, TOKEN, STORE_SLUG, get, set } from '../storage/LocalStorageManager';

const initialState = {
	isLoginRequest: false,
	error: null,
	userId: get('USER') ? get('USER') : null,
	userEmail: get('EMAIL') ? get('EMAIL') : '',
	userStore: get('STORE_SLUG') ? get('STORE_SLUG') : '',
};

const authReducer = (state, action) => {
	switch (action.type) {
		case 'LOGIN_REQUEST': {
			return {
				...state,
				isLoginRequest: true,
				error: null,
			};
		}
		case 'LOGIN_ERROR': {
			return {
				...state,
				isLoginRequest: false,
				error: action.payload,
			};
		}
		case 'LOGIN_SUCCESS': {
			return {
				...state,
				isLoginRequest: false,
				userId: action.payload.uuid,
				userEmail: action.payload.agent_email,
				error: null,
			};
		}
	}
};

const useAuth = ({ params, data, callbacks }) => {
	const [state, dispatch] = useReducer(authReducer, {
		...initialState,
	});

	const login = async (email, password) => {
		dispatch({ type: 'LOGIN_REQUEST' });

		try {
			const response = await instance.post('api/agents/login', {
				email,
				password,
			});
			set(USER, response.data.uuid);
			set(EMAIL, response.data.agent_email);
			set(TOKEN, response.data.token);
			set(STORE_SLUG, response.data.store_slug);
			dispatch({ type: 'LOGIN_SUCCESS', payload: response.data });
		} catch (e) {
			if (e.response && e.response.data) {
				dispatch({ type: 'LOGIN_ERROR', payload: e.response.data.error || 'Utente non trovato' });
			} else {
				dispatch({ type: 'LOGIN_ERROR', payload: 'SERVER ERROR' });
			}
		}
	};

	return {
		...state,
		login,
	};
};

export default useAuth;
