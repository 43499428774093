import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Icon, Typography, Tabs, Tab } from '@material-ui/core';
import { makeStyles, responsiveFontSizes, createMuiTheme } from '@material-ui/core/styles';
import { ReactComponent as LogoIcon } from '../assets/logo_IQOS.svg';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { get, EMAIL, USER, remove } from '../storage/LocalStorageManager';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const AppHeader = (props) => {
	const classes = useStyles();
	const [value, setValue] = useState(window.location.pathname === '/dashboard' ? 0 : 1);
	const history = useHistory();
	const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
	const matches = useMediaQuery('(min-width:600px)');

	useEffect(() => {
		handleChange(null, value);
	}, []);

	const handleChange = (event, newValue) => {
		setValue(newValue);
		if (newValue === 1) {
			history.push('/recalls');
		} else {
			history.push('/dashboard');
		}
	};

	const closeLogoutDialog = () => {
		setOpenLogoutDialog(false);
	};

	const logout = () => {
		remove(USER);
		history.push('/');
		setOpenLogoutDialog(false);
	};

	const renderTabs = () => {
		return (
			<Tabs
				style={{ height: !matches ? 40 : 64 }}
				value={value}
				indicatorColor="primary"
				textColor="primary"
				onChange={handleChange}
				aria-label="tabs"
				variant="fullWidth"
				TabIndicatorProps={{ style: { background: '#d6a76f' } }}
			>
				<Tab
					label="CHAT"
					style={{ color: value === 0 ? '#d6a76f' : 'lightGray', fontWeight: 'bold', marginTop: 8 }}
				/>
				<Tab
					label="RECALL"
					style={{ color: value === 1 ? '#d6a76f' : 'lightGray', fontWeight: 'bold', marginTop: 8 }}
				/>
			</Tabs>
		);
	};

	const renderLogoutDialog = () => {
		return (
			<Dialog
				open={openLogoutDialog}
				onClose={closeLogoutDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{'Log out'}</DialogTitle>
				<DialogContent style={{ width: 300 }}>
					<DialogContentText id="alert-dialog-description">Sei sicuro di voler uscire?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={closeLogoutDialog}>
						<Typography className={classes.logoutButtonText}>Anulla</Typography>
					</Button>
					<Button onClick={() => logout()}>
						<Typography className={classes.logoutButtonText}>SI</Typography>
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	return (
		<AppBar className={classes.appbarRoot} position="static" elevation={1}>
			<Toolbar className={matches ? classes.toolbar : classes.toolbarMobile}>
				<div style={{ marginTop: 4 }}>
					<LogoIcon />
				</div>
				<div className={!matches && classes.tabsMobile}>{renderTabs()}</div>

				<div className={classes.rightHeaderView}>
					<Typography className={classes.username}>{get(EMAIL)}</Typography>
					<IconButton onClick={() => setOpenLogoutDialog(true)}>
						<ExitToAppIcon style={{ fill: 'black', marginLeft: 10 }} />
					</IconButton>
				</div>
			</Toolbar>
			{renderLogoutDialog()}
		</AppBar>
	);
};

export default AppHeader;

function pxToRem(value) {
	return `${value / 16}rem`;
}

const useStyles = makeStyles((theme) => ({
	appbarRoot: {
		backgroundColor: 'white',
	},
	toolbar: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		position: 'relative',
	},
	toolbarMobile: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		position: 'relative',
		height: 100,
	},
	tabsMobile: {
		position: 'absolute',
		left: 0,
		bottom: 0,
		right: 0,
		width: '100%',
	},
	rightHeaderView: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	username: {
		fontSize: pxToRem(14),
		color: '#d6a76f',
		fontWeight: 'bold',
	},
	logoutButtonText: {
		color: 'black',
		fontSize: 16,
	},
}));
