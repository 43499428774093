import React, { useEffect, useState } from 'react';
import { Typography, Box, Grid, TextField, Button, Paper } from '@material-ui/core';
import Snackbar from '../components/Snackbar';
import { useAppContext , useAuthContext} from '../hooks/context/ContextFactory';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as LogoIcon } from '../assets/logo_IQOS.svg';

const Login = () => {
	
	const classes = useStyles();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const {
		login, 
		error
	} = useAuthContext()

	const { isOpenSnack, openSnack, closeSnack } = useAppContext();

	useEffect(() => {
		if (error === null) {
			closeSnack();
		} else {
			openSnack();
		}
	}, [error]);

	return (
		// <ContextProvider data={contextData}>
		<div>
			<Snackbar isOpen={isOpenSnack} type="error" snackbarMessage={error} />
			<div className={classes.rootWrapper}>
				<Box className={classes.loginWrapper} flexDirection="column">
					<LogoIcon />
					<Typography className={classes.title}>Shop Streaming Admin</Typography>
					<div className={classes.formWrapper}>
						<TextField
							fullWidth
							className={classes.field}
							id="username"
							value={email}
							variant="filled"
							placeholder={'Email'}
							onChange={(e) => {
								setEmail(e.target.value);
							}}
							InputProps={{ disableUnderline: true, classes: { root: classes.field } }}
						/>
						<TextField
							fullWidth
							className={classes.field}
							InputProps={{
								disableUnderline: true,
								classes: { root: classes.field },
							}}
							id="password"
							variant="filled"
							value={password}
							type="password"
							placeholder={'Password'}
							onChange={(e) => {
								setPassword(e.target.value);
							}}
							onKeyPress={(ev) => {
								if (ev.key === 'Enter') {
									login(email, password);
								}
							}}
						/>
					</div>

					<Button
						fullWidth
						className={classes.loginButton}
						onClick={() => login(email, password)}
						type="submit"
					>
						<Typography className={classes.buttonTitle}>ENTRA</Typography>
					</Button>
				</Box>
			</div>
		</div>
		// </ContextProvider>
	);
};

export default Login;

const useStyles = makeStyles((theme) => ({
	rootWrapper: {
		width: '100vw',
		height: '100vh',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	formWrapper: {
		height: 108,
		justifyContent: 'space-between',
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		width: 300,
	},
	field: {
		height: 48,
		borderBottomRightRadius: 4,
		borderBottomLeftRadius: 4,
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
	},
	loginWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: 20,
	},
	title: {
		fontSize: 18,
		color: 'black',
		fontWeight: 'bold',
		marginBottom: 40,
	},
	loginButton: {
		backgroundColor: '#d6a76f',
		height: 48,
		marginTop: 40,
		'&:hover': {
			//you want this to be the same as the backgroundColor above
			backgroundColor: '#d6a76f',
		},
	},
	inputText: {
		textAlign: 'center',
		fontSize: '16px',
		letterSpacing: '0.5px',
		lineHeight: '28px',
	},
	buttonTitle: {
		color: 'white',
		fontSize: '18',
	},
}));
