import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SendBirdProvider, ChannelList, ChannelSettings, Channel } from 'sendbird-uikit';
import 'sendbird-uikit/dist/index.css';
import { Box, Typography } from '@material-ui/core';
import CustomizedMessageItem from '../components/CustomizedMessageItems';
import CustomizedChannelPreviewItem from '../components/CustomizedMessageItems/CustomChannelPreview';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import 'moment/locale/it';
import useApp from '../hooks/useApp';
import { useAppContext } from '../hooks/context/ContextFactory';
import { usePubNub } from 'pubnub-react';
import RecallList from '../components/RecallList';
import { get, USER } from '../storage/LocalStorageManager';

const Recall = () => {
	const userId = get(USER);
	const { getRecalls, recalls } = useAppContext();
	const pubnub = usePubNub();
	const [channels] = useState([userId]);
	const [message, setMessage] = useState({ type: '' });

	const appHookObj = useApp({});

	const contextData = {
		...appHookObj,
	};

	useEffect(() => {
		var pubnubListeners = { message: handleMessage };
		pubnub.addListener(pubnubListeners);
		pubnub.subscribe({ channels });
		console.log('subscribe to pubnub');
		return () => {
			pubnub.removeListener(pubnubListeners);
		};
	}, [pubnub, channels]);

	useEffect(() => {
		getRecalls();
	}, []);

	useEffect(() => {
		console.log('recalls success dashboard', recalls);
	}, [recalls]);

	const handleMessage = (event) => {
		const message = event.message;
		if (message.type === 'recall') {
			console.log('PUBNUB RECEIVE EVENT =====>', event);
			getRecalls();
		}
	};

	return <RecallList data={recalls} onRefresh={() => getRecalls()} />;
};
export default Recall;
