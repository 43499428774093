import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import { Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import { ListItemIcon, Paper } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import RecallDetail from './RecallDetail';
import { useAppContext } from '../hooks/context/ContextFactory';

export default function RecallList(props) {
	const classes = useStyles();
	const { getRecallsProgress } = useAppContext();
	const [openDetail, setOpenDetail] = useState(false);
	const [detailItem, setDetailItem] = useState(null);

	const { editRecall } = useAppContext();

	console.log('recall data', props.data);

	const showDetail = (item) => {
		let request = { read: true, recall_id: item.id };
		editRecall(request);
		setDetailItem(item);
		setOpenDetail(true);
	};

	const onCloseDetail = () => {
		setOpenDetail(false);
		props.onRefresh();
	};

	const renderRow = (item, index) => {
		const { name, createdAt } = item;
		return (
			<div key={`${index}-div`}>
				<Button fullWidth onClick={() => console.log('click')} key={`${index}-button`}>
					<ListItem className={classes.item} key={`${index}-item`} onClick={() => showDetail(item)}>
						<div>
							<Typography
								style={{ fontWeight: item.read ? '500' : 'bold' }}
								className={classes.itemLabel}
							>
								{name}
							</Typography>
							<Typography className={classes.itemSubLabel}>
								{'del ' + createdAt.date + ' ' + 'ore ' + createdAt.time}
							</Typography>
						</div>
					</ListItem>
				</Button>
				<Divider light classes={{ root: classes.divider }} />
			</div>
		);
	};

	if (!props.data || (props.data.length === 0 && !getRecallsProgress)) {
		return (
			<Paper className={classes.root}>
				<Typography className={classes.placeholder}>{`Non ci sono richieste`}</Typography>
			</Paper>
		);
	}

	return (
		<Paper className={classes.root}>
			<List>{props.data.map((item, index) => renderRow(item, index))}</List>
			<RecallDetail data={detailItem} open={openDetail} onClose={() => onCloseDetail()} />
		</Paper>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: 10,
		width: '100%',
		height: '100vh',
		overflow: 'auto',
		backgroundColor: 'transparent',
		borderRadius: 0,
	},
	divider: {
		background: '#DCDCDC',
		opacity: 0.4,
	},
	item: {
		paddingLeft: 20,
		alignItems: 'flex-start',
		flexDirection: 'column',
		display: 'flex',
		justifyContent: 'flex-start',
	},
	itemLabel: {
		fontSize: 14,
		textTransform: 'capitalize',
	},
	itemSubLabel: {
		fontSize: 12,
		textTransform: 'lowercase',
	},
	placeholder: {
		fontSize: 18,
		marginTop: 100,
		color: 'black',
		fontWeight: 700,
		textAlign: 'center',
	},
}));
