import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import { ContextFactory, CONTEXTS, useAppContext } from '../hooks/context/ContextFactory';
import Snackbar from '../components/Snackbar';
import { get, STORE_SLUG } from '../storage/LocalStorageManager';
import { Paper } from '@material-ui/core';

const IqosRadio = withStyles({
	root: {
		color: '#d6a76f',
		'&$checked': {
			color: '#d6a76f',
		},
	},
	checked: {},
})((props) => <Radio color="default" {...props} />);

const RecallDetail = (props) => {
	const classes = useStyles();
	const [item, setItem] = useState(
		props.data
			? props.data
			: { name: '', phone: '', status: '', createdAt: { date: '', time: '' }, note: '', id: '' }
	);
	const [openSmsConfirmation, setOpenSmsConfirmation] = useState({ open: false, type: '' });
	const handleClose = () => {
		props.onClose();
	};
	const [status, setStatus] = useState(props.data ? props.data.status : '');
	const [note, setNote] = useState(props.data ? props.data.note : '');

	const { editRecall, editRecallSuccess, openSnack, isOpenSnack, recallSms } = useAppContext();

	useEffect(() => {
		if (props.data) {
			console.log('item', props.data);
			setItem(props.data);
			setStatus(props.data.status);
			setNote(props.data.note);
		}
	}, [props.data]);

	const handleChangeStatus = (event) => {
		console.log('status', event.target.value);
		setStatus(event.target.value);
		if (item && item.id != '') {
			let request = { recall_id: item.id, status: event.target.value };
			editRecall(request);
		}
	};

	const handleCloseSmsDialog = () => {
		setOpenSmsConfirmation({ open: false, type: '' });
	};

	const updateNote = () => {
		if (item && item.id != '') {
			let request = { recall_id: item.id, note };
			editRecall(request);
			openSnack();
		}
	};

	const sendSMS = (type) => {
		let request = {
			phone: item.phone,
			type,
			videocall_url: `https://web-vs.iqositalia.it/s/${get(STORE_SLUG)}`,
		};
		console.log('sms request', request);
		recallSms(request);
		handleCloseSmsDialog();
		openSnack();
	};

	return (
		<div>
			<Dialog scroll="body" onClose={handleClose} aria-labelledby="customized-dialog-title" open={props.open}>
				<Snackbar isOpen={isOpenSnack} type="success" snackbarMessage={'Fatto!'} />
				<IconButton style={{ position: 'absolute', right: 8, top: 0 }} onClick={() => props.onClose()}>
					<CloseIcon />
				</IconButton>
				<DialogTitle style={{ marginRight: 28 }} id="customized-dialog-title" onClose={handleClose}>
					<Typography style={{ fontWeight: 'bold', fontSize: 18 }}>{item.name}</Typography>
					<Typography gutterBottom style={{ fontStyle: 'italic', fontSize: 14 }}>
						{`ha provato a contattare la Boutique il ` +
							item.createdAt.date +
							' alle ore ' +
							item.createdAt.time}
					</Typography>
					<Typography gutterBottom style={{ fontStyle: 'italic', fontSize: 14 }}>
						{`Numero di telefono ` + item.phone}
					</Typography>
					<Button
						fullWidth
						className={classes.callButton}
						variant="contained"
						// onClick={() => window.open(`tel:${item.phone}`)}
						href={`tel:${item.phone}`}
					>
						Chiama
					</Button>
				</DialogTitle>
				<DialogContent dividers>
					<div className={classes.checkboxesRoot}>
						<div className={classes.checkBoxWrapperL}>
							<IqosRadio
								onChange={(e) => handleChangeStatus(e)}
								value={'recall'}
								checked={status === 'recall'}
							/>
							<Typography>Richiamato</Typography>
						</div>
						<div className={classes.checkBoxWrapperR}>
							<IqosRadio
								onChange={(e) => handleChangeStatus(e)}
								value={'decline'}
								checked={status === 'decline'}
							/>
							<Typography>Non risponde</Typography>
						</div>
					</div>
				</DialogContent>
				<DialogContent dividers>
					<div className={classes.buttonsContent}>
						<Button
							fullWidth
							className={classes.smsButton}
							variant="contained"
							onClick={() => setOpenSmsConfirmation({ open: true, type: 'videocall' })}
						>
							SMS Videochiamata
						</Button>
						<Button
							fullWidth
							className={classes.smsButton}
							variant="contained"
							onClick={() => setOpenSmsConfirmation({ open: true, type: 'contact' })}
						>
							SMS Nessuna risposta
						</Button>
					</div>
				</DialogContent>
				<DialogContent>
					<TextField
						className={classes.fieldFeedback}
						fullWidth
						value={note}
						multiline
						rowsMax={5}
						rows={5}
						variant="filled"
						placeholder="Nota"
						color="primary"
						InputProps={{
							// classes: {
							// 	notchedOutline: classes.notchedOutline,
							// 	input: classes.notchedOutline,
							// },
							disableUnderline: true,
						}}
						InputLabelProps={{
							style: { color: 'black' },
						}}
						onChange={(e) => setNote(e.target.value)}
					/>
				</DialogContent>
				<DialogContent>
					<div className={classes.buttonWrapper}>
						<Button
							fullWidth
							className={classes.editButton}
							variant="contained"
							onClick={() => updateNote()}
						>
							SALVA
						</Button>
					</div>
				</DialogContent>
			</Dialog>
			<Dialog
				open={openSmsConfirmation.open}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				style={{ margin: 10 }}
			>
				<DialogContent>Sei sicuro di voler inviare l'SMS a questo cliente?</DialogContent>
				<DialogActions>
					<Button onClick={() => handleCloseSmsDialog()}>No</Button>
					<Button onClick={() => sendSMS(openSmsConfirmation.type)}>Si</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	checkboxesRoot: {
		height: 48,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	checkBoxWrapperL: {
		display: 'flex',
		alignItems: 'center',
	},
	checkBoxWrapperR: {
		display: 'flex',
		alignItems: 'center',
		borderRadius: 4,
	},
	fieldFeedback: {
		// color: 'black',
		// height: 128,
		marginTop: 20,
		backgroundColor: 'rgb(230, 202, 169, 0.5)',
		borderRadius: 4,
		'& .MuiFilledInput-root': {
			background: 'transparent',
		},
	},
	notchedOutline: {
		// borderWidth: '2px',
		// borderColor: 'transparent',
		// color: 'transparent',
		// '&::placeholder': {
		// 	color: 'transparent',
		// },
		height: '80%',
	},
	notchedOutlineForm: {
		borderWidth: '2px',
		fontFamily: 'Noto Sans',
		borderColor: 'transparent',
		color: 'transparent',
		'&::placeholder': {
			color: 'transparent',
		},
		// backgroundColor: '#e6caa9',
	},
	buttonsContent: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
	},
	buttonWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	smsButton: {
		width: '48%',
		height: 40,
		fontWeight: 'bold',
		// letterSpacing: 1.92,
		fontSize: 10,
		backgroundColor: '#d6a76f',
		color: 'white',
	},
	callButton: {
		borderWidth: '1px',
		borderColor: '#d6a76f',
		height: 40,
		width: '50%',
		fontWeight: 'bold',
		letterSpacing: 1.92,
		backgroundColor: '#d6a76f',
		color: 'white',
		fontSize: 12,
	},
	editButton: {
		height: 40,
		fontWeight: 'bold',
		letterSpacing: 1.92,
		fontSize: 12,
		backgroundColor: '#d6a76f',
		color: 'white',
		marginBottom: 20,
	},
}));

export default RecallDetail;
