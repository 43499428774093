import React, { useMemo } from 'react';

import { sendBirdSelectors, withSendBird } from 'sendbird-uikit';

import AdminMessage from './AdminMessage';
import FileMessage from './FileMessage';
import UserMessage from './UserMessage';

function CustomizedMessageItem(props) {
	const { message, onDeleteMessage, onUpdateMessage, userId, channel, sdk, sendUserMessage, sendFileMessage } = props;

	const MessageHOC = useMemo(() => {
		if (message.isAdminMessage && message.isAdminMessage()) {
			return () => <AdminMessage message={message} />;
		} else if (message.isFileMessage && message.isFileMessage()) {
			return () => (
				<FileMessage
					message={message}
					userId={userId}
					onDeleteMessage={onDeleteMessage}
					channel={channel}
					sdk={sdk}
					sendUserMessage={sendUserMessage}
					sendFileMessage={sendFileMessage}
				/>
			);
		} else if (message.isUserMessage && message.isUserMessage()) {
			return () => (
				<UserMessage
					message={message}
					userId={userId}
					onDeleteMessage={onDeleteMessage}
					onUpdateMessage={onUpdateMessage}
					channel={channel}
					sdk={sdk}
					sendUserMessage={sendUserMessage}
				/>
			);
		}
		return () => <div />;
	}, [message, userId, onDeleteMessage, onUpdateMessage, channel]);

	return (
		<div id={message.messageId} className="customized-message-item">
			<MessageHOC />
			<br />
		</div>
	);
}

const mapStoreToProps = (store) => {
	const sendUserMessage = sendBirdSelectors.getSendUserMessage(store);
	const sdk = sendBirdSelectors.getSdk(store);
	const sendFileMessage = sendBirdSelectors.getSendFileMessage(store);
	return {
		sendUserMessage,
		sdk,
		sendFileMessage,
	};
};

export default withSendBird(CustomizedMessageItem, mapStoreToProps);
