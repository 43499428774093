import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {
	USER,
	get
} from '../storage/LocalStorageManager';

const DEFAULT_REDIRECT_ROUTE = '/';

const GuardedRoute = (props) => {
    const loggedIn = get(USER) !== null
    const redirect = props.authRoute ? loggedIn : !loggedIn
	return (
		redirect ? (
			<Redirect to={props.redirectTo || DEFAULT_REDIRECT_ROUTE} />
		) : (
			<Route path={props.path} exact={props.exact} component={props.component} />
		)
    )
}

export default GuardedRoute