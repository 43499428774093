import axios from 'axios';
import env from './config';
import { get, TOKEN } from '../storage/LocalStorageManager';

const instance = axios.create({
	baseURL: env().baseURL,
	timeout: 10000,
});

export { instance };
