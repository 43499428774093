import React, { useState, useMemo } from 'react';

import {
	Card,
	CardHeader,
	Avatar,
	CardContent,
	CardActions,
	Typography,
	Button,
	TextField,
	Box,
	IconButton,
	Popover,
	Fade,
	Paper,
	ClickAwayListener,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CheckIcon from '@material-ui/icons/Check';
import 'moment/locale/it'; // without this line it didn't work
import Moment from 'react-moment';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Linkify from 'react-linkify';
import { SecureLink } from 'react-secure-link';

export default function UserMessage(props) {
	// props
	const { message, userId, onDeleteMessage, onUpdateMessage, channel, sdk, sendUserMessage } = props;
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

	// useState
	const [pressedUpdate, setPressedUpdate] = useState(false);
	const [messageText, changeMessageText] = useState(message.message);
	const [isRead, setIsRead] = useState(false);
	const [numberUnread, setNumberUnread] = useState(0);

	const sendMessage = (origMessage, destChannel) => {
		console.log('Sending message to channel', destChannel);
		const params = new sdk.UserMessageParams();
		params.message = origMessage.message;
		sendUserMessage(destChannel.url, params)
			.then((message) => {
				console.log('Message sent', message);

				// IF SENT
				// For a text message
				const params = new sdk.UserMessageParams();
				params.data = JSON.stringify({ status: 'forwarded' });

				// The MESSAGE_ID below indicates the unique message ID of a UserMessage object to update.
				console.log('Updating message status', origMessage.messageId);
				// destChannel.updateUserMessage(origMessage.messageId, params, function(message, error) {
				//     if (error) {
				//       console.error("Error", error);
				//       return;
				//     } else {
				//       console.log('Message updated', message);
				//     }
				// });
				const emojiKey = 'smile';
				destChannel.addReaction(origMessage, emojiKey, function (reactionEvent, error) {
					if (!error) {
						origMessage.applyReactionEvent(reactionEvent);
					}
				});
			})
			.catch((error) => {
				console.log(error.message);
			});
	};

	const onForwardMessage = (message) => {
		console.log('Message', message);
		console.log('Channel', channel);
		console.log('channel ' + message.sender.nickname + ' - nickname ' + channel.name);

		var channelListQuery = sdk.GroupChannel.createMyGroupChannelListQuery();
		channelListQuery.includeEmpty = true;
		channelListQuery.order = 'latest_last_message'; // 'chronological', 'latest_last_message', 'channel_name_alphabetical', and 'metadata_value_alphabetical'
		channelListQuery.limit = 1; // The value of pagination limit could be set up to 100.
		channelListQuery.channelNameContainsFilter = message.sender.nickname;
		channelListQuery.nicknameContainsFilter = channel.name;

		if (channelListQuery.hasNext) {
			channelListQuery.next(function (channelList, error) {
				if (error) {
					return;
				}

				console.log('CH', channelList);

				if (channelList) {
					const isForwarded =
						message.reactions !== null &&
						message.reactions.length > 0 &&
						message.reactions[0].key === 'smile';
					if (isForwarded) {
						if (window.confirm('Questo messaggio è stato già inoltrato, vuoi procedere ugualmente?')) {
							console.log('Sending message');
							sendMessage(message, channelList[0]);
						}
					} else {
						console.log('Sending message');
						sendMessage(message, channelList[0]);
					}
				}
			});
		}
	};

	//console.log('MESSAGE', message);
	const isForwarded =
		message.reactions !== null && message.reactions.length > 0 && message.reactions[0].key === 'smile';

	useMemo(() => {
		setNumberUnread(channel.getReadReceipt(message));
		//setIsRead(numberUnread === 0);
		// console.log('READ', isRead);
	}, [channel, message]);

	// if (isRead === false) {
	// 	let val = channel.getReadReceipt(message);
	// 	if (val > 0) {
	// 		setIsRead(true);
	// 	}
	// }

	//actions popover
	const handleClickMore = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const pressEditMessage = () => {
		setAnchorEl(null);
		setPressedUpdate(true);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const renderDeleteDialog = () => {
		return (
			<Dialog
				open={openDeleteDialog}
				onClose={() => setOpenDeleteDialog(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{''}</DialogTitle>
				<DialogContent style={{ width: 300 }}>
					<DialogContentText id="alert-dialog-description">
						Sei sicuro di voler eliminare questo messaggio?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenDeleteDialog(false)}>
						<Typography>No</Typography>
					</Button>
					<Button onClick={() => onDeleteMessage(message)}>
						<Typography>SI</Typography>
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	const getMessageText = (message) => {
		if (message.message === 'direct_call:dial') {
			return 'Chiamata iniziata';
		}
		if (message.message === 'direct_call:end') {
			return 'Chiamata terminata';
		}
		return message.message;
	};

	const componentDecorator = (href, text, key) => (
		<SecureLink style={{ color: 'black' }} href={href} key={key}>
			{text}
		</SecureLink>
	);

	return (
		<div className={`user-message ${message.sender && message.sender.userId === userId ? 'mine' : ''}`}>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
			>
				<ClickAwayListener onClickAway={() => setAnchorEl(null)}>
					<div className={classes.actionsPopover}>
						{!pressedUpdate && (
							<Button
								size="small"
								variant="outlined"
								elevation={0}
								onClick={() => setOpenDeleteDialog(true)}
							>
								Elimina
							</Button>
						)}
						{!pressedUpdate && (
							<Button
								size="small"
								variant="outlined"
								elevation={0}
								onClick={() => {
									pressEditMessage();
								}}
							>
								Modifica
							</Button>
						)}
					</div>
				</ClickAwayListener>
			</Popover>

			<Card className={classes.card} elevation={0}>
				<div
					className={
						message.sender && message.sender.userId === userId
							? classes.myMessageWrapper
							: classes.messageWrapper
					}
				>
					{/* <Typography
						className={message.sender && message.sender.userId === userId ? null : classes.senderText}
					>
						{message.sender && message.sender.userId === userId ? '' : message.sender.nickname}
					</Typography> */}
					{message.sender && message.sender.userId === userId && !pressedUpdate && (
						<IconButton className={classes.messageActionsBtn} onClick={(e) => handleClickMore(e)}>
							<MoreVertIcon style={{ fill: '#d6a76f' }} />
						</IconButton>
					)}
					{!pressedUpdate && (
						<Typography
							elevation={1}
							variant="body2"
							component="span"
							display="inline"
							className={
								message.sender && message.sender.userId === userId
									? classes.messageMyText
									: classes.messageText
							}
						>
							<Linkify componentDecorator={componentDecorator}>{getMessageText(message)}</Linkify>
						</Typography>
					)}
					{pressedUpdate && (
						<div className={classes.editMessageWrapper}>
							<Button
								style={{ marginRight: 10 }}
								size="small"
								variant="outlined"
								elevation={0}
								onClick={() => setPressedUpdate(false)}
							>
								Annulla
							</Button>

							<Button
								style={{ marginRight: 10 }}
								size="small"
								variant="outlined"
								elevation={0}
								onClick={() => onUpdateMessage(message.messageId, messageText)}
							>
								Salva
							</Button>
							<div className={classes.editMessageField}>
								<TextField
									style={{ maxWidth: '100%' }}
									label="Modifica"
									multiline
									errorStyle={{ color: 'green' }}
									fullWidth
									variant="filled"
									rowsMax={4}
									value={messageText}
									InputProps={{ className: classes.input }}
									InputLabelProps={{ className: classes.inputLabel }}
									onChange={(event) => {
										changeMessageText(event.target.value);
									}}
								/>
							</div>
						</div>
					)}
					<div className={classes.timeWrapper}>
						{message.sender && message.sender.userId === userId && (
							<CardActions>
								{isRead && (
									<span>
										<Typography variant="caption">
											<i style={{ color: 'green' }}>✓</i> letto{' '}
											{/* {channel.memberCount - numberUnread}/{channel.memberCount} */}
										</Typography>
									</span>
								)}
							</CardActions>
						)}
						<Moment className={classes.timeLabel} fromNow>
							{message.createdAt}
						</Moment>
					</div>
				</div>
				{/* {message.sender && message.sender.userId === userId && (
					<CardActions>
						{isRead && (
							<span>
								<Typography variant="caption">
									<i style={{ color: 'green' }}>✓</i> Letto {channel.memberCount - numberUnread}/
									{channel.memberCount}
								</Typography>
							</span>
						)}
					</CardActions>
				)} */}
				{/* {message.sender && message.sender.userId !== userId && (
					<CardActions>
						{!pressedUpdate && (
							<Button
								size="small"
								variant="outlined"
								elevation={0}
								onClick={() => onForwardMessage(message)}
							>
								Inoltra
							</Button>
						)}
						{isForwarded && <CheckIcon style={{ color: '#D02C2A' }} />}
					</CardActions>
				)} */}
			</Card>
			{renderDeleteDialog()}
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	card: {
		backgroundColor: 'transparent',
		width: '100%',
	},
	timeLabel: {
		fontSize: 10,
		color: 'black',
	},
	mine: {},
	outbound: {
		// backgroundColor: '#acacac',
		textAlign: 'right',
		color: 'white',
		float: 'right',
		borderRadius: 20,
		elevation: 1,
		maxWdith: '60%',
	},
	senderText: {
		fontSize: 12,
		color: 'black',
	},
	messageText: {
		fontSize: 12,
		color: 'black',
		backgroundColor: 'white',
		borderRadius: 20,
		paddingLeft: 16,
		paddingRight: 16,
		paddingTop: 10,
		paddingBottom: 10,
		maxWidth: '60%',
		textAlign: 'left',
		wordWrap: 'break-word',
	},
	messageMyText: {
		fontSize: 12,
		color: 'white',
		backgroundColor: '#acacac',
		borderRadius: 20,
		paddingLeft: 16,
		paddingRight: 16,
		paddingTop: 10,
		paddingBottom: 10,
		maxWidth: '60%',
		textAlign: 'right',
		wordWrap: 'break-word',
		marginRight: 50,
	},
	inbound: {
		backgroundColor: 'white',
		textAlign: 'left',
		float: 'left',
		maxWdith: '60%',
		borderRadius: 16,
	},
	myMessageWrapper: {
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'wrap',
		alignItems: 'flex-end',
		marginTop: 10,
		position: 'relative',
	},
	messageWrapper: {
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'wrap',
		alignItems: 'flex-start',
		marginTop: 10,
	},
	messageActionsBtn: {
		right: 2,
		top: 0,
		position: 'absolute',
	},
	actionsPopover: {
		padding: 10,
		height: 80,
		width: 100,
		justifyContent: 'space-between',
		backgroundColor: 'white',
		borderRadius: 4,
		display: 'flex',
		flexDirection: 'column',
	},
	timeWrapper: {
		display: 'flex',
		alignItems: 'center',
		marginRight: 50,
	},
	editMessageWrapper: {
		display: 'flex',
		alignItems: 'flex-end',
	},
	editMessageField: {
		marginRight: 50,
	},
	input: {
		'&:before': {
			borderBottom: `1px solid  rgba(166,166,166,0.5)`,
		},
		'&:after': {
			borderBottom: `2px solid  #d6a76f`,
		},
		'&&&&:hover:before': {
			borderBottom: `1px solid  #d6a76f`,
		},
	},
	inputLabel: {
		'&.Mui-focused': {
			color: '#d6a76f',
		},
		'&:before': {
			color: `#d6a76f`,
		},
		'&:after': {
			color: `#d6a76f`,
		},
		'&&&&:hover:before': {
			color: `#d6a76f`,
		},
	},
}));
